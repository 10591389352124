<template>
  <NuxtLink :to="localePath(offerRoute)" class="offer-item">
    <div class="offer-item-header">
      <BaseImageExternal
        v-if="offer.shop.fileUrl"
        :src="offer.shop.fileUrl"
        class="shop-logo"
        :alt="$t('shop.cover-alt', [offer.shop.name])"
      />
      <BaseText v-if="offer.discountPercent" class="discount-percentage">
        {{ $t("offers.discount-percent", [Math.trunc(offer.discountPercent)]) }}
      </BaseText>
    </div>

    <BaseImageExternal
      v-if="hotspot.fileUrl"
      :src="hotspot.fileUrl"
      class="offer-item-cover"
      :alt="$t('offers.cover-alt', [offer.name])"
      :aspect-ratio="hotspot.width / hotspot.height"
    />

    <div class="offer-item-info">
      <BaseText class="offer-item-title">{{ offer.name }} </BaseText>
      <!-- <BaseText class="offer-item-description">{{
        Offer geldig t/m goes here (later)
      }}</BaseText> -->
      <div class="offer-discount-prices">
        <BaseText v-if="offer.priceBeforeDiscount" class="price-before">{{
          currencyFormatter.format(offer.priceBeforeDiscount)
        }}</BaseText>
        <BaseText v-if="offer.priceAfterDiscount" class="price-after">{{
          currencyFormatter.format(offer.priceAfterDiscount)
        }}</BaseText>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import {
  type HotspotProductEntity,
  type OfferFragment,
} from "~/graphql/generated";

const props = defineProps<{
  offer: OfferFragment;
  hotspotId: string;
}>();

const hotspot = ref(props.offer.hotspot as HotspotProductEntity);

const localePath = useLocalePath();

const { currencyFormatter } = useUtils();

const isEmbed = isEmbedded();
const offerRoute = computed(() => {
  return isEmbed
    ? {
        name: "embed-id-pagenumber",
        params: {
          id: props.offer.brochureId,
          pagenumber: props.offer.pageIndex.toString(),
        },
        query: { offer: props.hotspotId },
      }
    : {
        name: "slug-offers-id-pagenumber",
        params: {
          slug: props.offer.shop.slug,
          id: props.offer.brochureId,
          pagenumber: props.offer.pageIndex.toString(),
        },
        query: { offer: props.hotspotId },
      };
});
</script>

<style lang="scss" scoped>
.offer-item {
  background-color: $light-100;
  border: 1px solid $light-80;
  overflow: hidden;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .offer-item-header {
    padding: 1rem 0 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .shop-logo {
      height: auto;
      max-height: 2rem;
      width: auto;
      max-width: 33%;
    }

    .discount-percentage {
      background-color: var(--primary);
      color: $light-100;
      padding: 0.25rem 0.5rem;
      border-radius: $border-radius-small 0 0 $border-radius-small;
      font-size: 0.825rem;
      font-weight: 400;
    }
  }
  .offer-item-cover {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 300px;
  }
  .offer-item-info {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    .offer-item-title {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 145%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .offer-item-description,
    .price-before {
      font-size: 0.825rem;
      color: $dark-50;
      line-height: 130%;
    }
  }

  .offer-discount-prices {
    display: flex;
    .price-before {
      text-decoration: line-through;
    }
    .price-after {
      color: var(--primary);
      font-size: 1.125rem;
      margin-left: auto;
    }
  }
}
</style>
